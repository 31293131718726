@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Satoshi' sans-serif;
  font-style: normal;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  @apply bg-slate-50 text-slate-900 dark:bg-slate-900 dark:text-slate-50;
}

body:focus {
  outline: none !important;
}

.fill-sky-500 {
  fill: #0ea5e9;
}

.fill-emerald-400 {
  fill: #34d399;
}

.fill-orange-500 {
  fill: #f97316;
}

.fill-sky-400 {
  fill: #38bdf8;
}

.fill-emerald-500 {
  fill: #10b981;
}

.fill-orange-400 {
  fill: #fb923c;
}
