.toggle {
  width: 100px;
  height: 41px;
  border-radius: 52px;
  position: relative;
  cursor: pointer;

  display: flex;
  align-items: center;
  padding: 0 0.5em;

  -webkit-tap-highlight-color: transparent;
}
